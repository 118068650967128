/* 
  disable horizontal scroll because of the Dropdown component
*/
body {
  overflow-x: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.next-gen-teaser {
  width: 100%;
  height: auto;
  max-height: 300px;
  border-radius: 5px;
  cursor: pointer;
  //max-width: 588px;
}

.max-width-200 {
  max-width: 200px;
}

.min-width-200 {
  min-width: 200px;
}

.min-width-220 {
  min-width: 220px;
}

.min-width-45 {
  min-width: 45%;
}

.word-wrap-normal {
  word-wrap: normal;
}

.pc-breadcrumb-item {
  padding-left: 0.5rem;

  &::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #adb5bd;
    content: "-";
  }
}

.pc-portin-back-button {
  white-space: nowrap;
  font-weight: bold;
}

.pc-ad-container {
  justify-content: center;
  display: flex;
}

.pc-ad-container img {
  max-width: 100%;
  border: 0;
}

.pc-logo {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}

.pc-logo img {
  width: 100%;
}

.pc-table-header {
  height: 50px;
  display: flex;
  flex-direction: column;
}

.add-supplement-modal-section {
  flex-basis: 25%;
  flex-grow: 1;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-basis-16 {
  flex-basis: 16%;
}

.flex-basis-25 {
  flex-basis: 25%;
}

.flex-basis-33 {
  flex-basis: 33%;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.flex-basis-95 {
  flex-basis: 95%;
}

.pc-timeline-container {
  overflow-y: scroll;
}

.pc-last-form-input {
  margin-bottom: 0 !important;
}

.pc-inline-loading-indicator {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.otp-col {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.otp-error {
  margin: 3px;
}

.nopadding {
  padding: 0 !important;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.text-align-end {
  text-align: end;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.pc-no-shadow {
  box-shadow: none;
}

.hight-100 {
  height: 100%;
}

.pc-navbar-img {
  height: 34px !important;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.navbar-horizontal .navbar-brand img {
  height: 70px !important;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.modal-header-title {
  font-size: 16px;
}

.modal-history {
  min-width: 750px !important;
}

.modal-alert-tollfree-reserve {
  min-width: 500px !important;
}

.toll-free-query {
  background-color: #474747 !important;
  padding: 1rem !important;
}

.side-nav-img {
  max-width: 1.5rem;
  margin-right: 10px;
  filter: invert(41%) sepia(5%) saturate(53%) hue-rotate(314deg) brightness(93%) contrast(88%);
}

.navbar-vertical.navbar-expand-xs .navbar-nav>.nav-item>.nav-link.active .text-sidebar {
  color: #4196D2 !important;
}

.navbar-vertical.navbar-expand-xs .navbar-nav>.nav-item>.nav-link.active .side-nav-img {
  filter: invert(53%) sepia(81%) saturate(596%) hue-rotate(176deg) brightness(88%) contrast(86%);
}

.text-sidebar {
  color: #666666 !important;
}

.top-modify-resp {
  top: 14px;
}

.btn-semi-warning {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-semi-warning:hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-semi-warning:focus,
.btn-semi-warning.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(251, 99, 64, 0.5);
}

.btn-semi-warning.disabled,
.btn-semi-warning:disabled {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-semi-warning:not(:disabled):not(.disabled):active,
.btn-semi-warning:not(:disabled):not(.disabled).active,
.show>.btn-semi-warning.dropdown-toggle {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-semi-warning:not(:disabled):not(.disabled):active:focus,
.btn-semi-warning:not(:disabled):not(.disabled).active:focus,
.show>.btn-semi-warning.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 #ffc107;
}

.btn-semi-danger {
  color: #fff;
  background-color: #F5365C;
  border-color: #F5365C;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-semi-danger:hover {
  color: #fff;
  background-color: #F5365C;
  border-color: #F5365C;
}

.btn-semi-danger:focus,
.btn-semi-danger.focus {
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(251, 99, 64, 0.5);
}

.btn-semi-danger.disabled,
.btn-semi-danger:disabled {
  color: #fff;
  background-color: #F5365C;
  border-color: #F5365C;
}

.btn-semi-danger:not(:disabled):not(.disabled):active,
.btn-semi-danger:not(:disabled):not(.disabled).active,
.show>.btn-semi-danger.dropdown-toggle {
  color: #fff;
  background-color: #F5365C;
  border-color: #F5365C;
}

.btn-semi-danger:not(:disabled):not(.disabled):active:focus,
.btn-semi-danger:not(:disabled):not(.disabled).active:focus,
.show>.btn-semi-danger.dropdown-toggle:focus {
  box-shadow: none, 0 0 0 0 rgba(251, 99, 64, 0.5);
}

.btn-text-switc-off {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
}

.footer-color {
  color: #2e5599 !important;
}

.hyper-link {
  color: #fff !important;
  ;
}

.pc-navbar-image {
  height: 25px;

  &:hover {
    opacity: 0.5;
  }
}

.pc-display-block {
  display: block;
}

.pc-white-space-normal {
  white-space: normal;
}

.pc-number-explorer-bg {
  color: $pc-number-explorer-color;
}

.atl-toll-free-explorer-bg {
  color: $pc-network-explorer-color;
}

.pc-soa-order-bg {
  color: $pc-soa-order-color;
}

.toll-free-title {
  color: $pc-network-explorer-color;
}

.f-big {
  font-size: 0.8125rem !important;
}

.dr-disabled {
  opacity: 0.5;
}

.pc-input-group {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0;
  -webkit-transition: box-shadow 0.15s ease;
  transition: box-shadow 0.15s ease;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.pc-list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  max-height: inherit;
  overflow-y: scroll;
  height: 100px;
}

.pc-list-group-item {
  position: relative;
  display: block;
  margin-bottom: -1px;
  background-color: #fff;
}

.pc-hide-button {
  float: right;
  visibility: hidden;
  cursor: pointer;
}

.pc-text-link {
  text-decoration: underline;
  cursor: pointer;
  color: $link-color;
}

.pc-success-button {
  color: #2dce89;
  background-color: white;
}

.pc-list-group-item:hover .pc-hide-button {
  visibility: visible;
}

.pc-search-container {
  display: flex;
}

.text-navigate {
  color: #525F7F !important
}

.success-header {
  background-color: #2dce89 !important;
}

.danger-header {
  background-color: #F5365C !important;
}

.warning-header {
  background-color: #ffc107 !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.text-border {
  border: 2px solid #6B7692;
  border-radius: 4px;
  margin-top: 10px;
  padding: 10px;
}

.pd_0 {
  padding: 0rem !important;
}

// label {
//   user-select: none;
// }

.switch-wrapper>input[type="checkbox"] {
  opacity: 0;
  position: absolute;
}

.switch-wrapper>input[type="checkbox"]+.switch {
  transform: translateX(5px);
}

.switch-wrapper>input[type="checkbox"]:checked+.switch {
  transform: translateX(50%) translateX(-19px);
}

.switch-wrapper.large>input[type="checkbox"]:checked+.switch {
  transform: translateX(50%) translateX(-35px);
}

.switch-wrapper>input[type="checkbox"]:disabled+.switch {
  background: #ccc;
}

.switch-wrapper {
  border-radius: 20px;
  cursor: pointer;
  height: 24px;
  float: left;
  overflow: hidden;
  position: relative;
  width: 48px;
}

.switch-wrapper.large {
  height: 35px;
  width: 65px;
}

.switch-wrapper>.switch {
  color: #fff;
  display: inline-block;
  height: 100%;
  left: -100%;
  position: relative;
  transition: 100ms linear;
  width: 200%;
}

.switch-wrapper>.switch>.switch-handle {
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  height: 14px;
  left: 50%;
  position: relative;
  top: 4px;
  width: 14px;
  z-index: 3;
}

.switch-wrapper.large>.switch>.switch-handle {
  height: 27px;
  width: 30px;
}

.switch-label {
  float: left;
  line-height: 2.5rem;
  margin-left: 10px;
}

.switch-wrapper.large+.switch-label {
  line-height: 4rem;
  margin-left: 15px;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.text-white {
  color: #fff;
}

.font-checkbox {
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.csv_button {
  margin-right: 1rem !important;
}

.margin-left-15 {
  margin-left: 15px !important;
}

.padding-card {
  padding: 0rem !important;
}

.sub-row {
  margin-top: -5px !important;
}

.padding-card-col {
  padding: 2px !important;
}

.checkbox-header {
  color: #000000;
  font-weight: 800;
}

.padding-sub-card {
  padding: 0.5rem !important;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.popup_padding {
  padding: 20px !important;
  color: 'black';
}

.ul-outage {
  color: #f5365c !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 19px !important;
}

.li-outage {
  color: #525F7F !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 19px !important;
}

.ul-table-header {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 19px !important;
}

.pc-create-dropdown {
  display: flex;
  align-items: flex-end;
}

.modal-header {
  border-bottom: 1px solid #e9ecef;
}

.pc-search {
  width: 400px;
  font-size: 0.875rem;
  display: inline-block;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.pc-flexwrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.card-font {
  font-size: 0.8125rem;
}

.bg-green-clr {
  color: #2dce89;
  font-size: 15px;
}

.font-clr {
  color: #525f7f !important;
  font-size: 0.8125rem !important;
  font-weight: 600;
}

.margin-top-10 {
  margin-top: 10px;
}

.pc-btn {
  letter-spacing: 0.025em;
  font-size: 0.875rem;
  will-change: transform;
  color: #fff;
  border-color: #172b4d;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.pc-nav-border-bottom {
  border-bottom: 1px solid $pc-background-color !important;
}

.border-bottom-1 {
  border-bottom: 1px solid #e9ecef;
}

.pc-bg {
  color: $pc-background-color;
}

.pc-hidden-lg {
  display: none;
}

#footer-main {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.pc-accept {
  border-color: $pc-spring-green;
}

.pc-reject {
  border-color: $pc-torch-red;
}

.pc-active {
  border-color: $pc-dodger-blue;
}

.pc-invalid-input {
  border-color: $orange;
}

.pc-invalid-input:focus {
  border-color: $orange;
}

.focused {
  .pc-invalid-input:focus {
    border-color: $orange;
  }
}

.pc-default {
  border-color: $pc-light-gray;
}

.pc-close-modal {
  z-index: 1;
}

.pc-drag-drop-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.pc-btn-card {
  display: block;
}

.pc-card-header {
  min-height: 67px;
}

.pc-column-without-header {
  margin-top: 21px;
}

.rdtCounter .rdtCount {
  padding: 6px !important;
}

table .left-datepicker .rdtPicker {
  right: 0px;
  left: auto;
}

.top-datepicker .rdtPicker {
  bottom: 70px;
  top: auto;
}

table .rdtPicker {
  max-width: 300px !important;
}

table .rdtPicker table {
  max-width: 300px !important;
  table-layout: fixed;
}

table .rdtPicker table thead tr th {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

table .rdtPicker table tbody tr td {
  border-color: $white !important;
  padding-left: 1px !important;
  padding-right: 1px !important;
  vertical-align: middle !important;
  text-transform: none !important;
  border-top: none;
}

table .rdtPicker table thead th {
  text-transform: none;
  background-color: white !important;
}

.table-settings-modal-content {
  max-width: 400px !important;
}

.table-reports-modal-content {
  max-width: 1100px;
}

.table-settings-modal-body {
  padding: 0rem 1rem 1rem 1rem !important;
}

th .custom-control-label {
  font-size: 0.65rem !important;
}

th .custom-control-label::before {
  top: 0 !important;
}

th .custom-control-label::after {
  top: 0 !important;
}

td .custom-control-label::before {
  top: 2px !important;
}

td .custom-control-label::after {
  top: 2px !important;
}

.select-all {
  text-decoration: underline;
  cursor: pointer;
}

.dropdown-content {
  width: 100%;
}

.table-settings-modal-customviewsettings-spid-filter-list {
  overflow-y: scroll;
  height: 316px;
  width: 377px;
  padding-left: 31px;
}

.table-settings-modal-customviewsettings-tab {
  width: 169px;
  height: 32px;
  line-height: 0.5rem;
}

label[for="customCheckcsrRequest"] {
  width: 74vw;
}

.pc-btn-audit {
  background-color: #5e72e4 !important;
  color: white;
}

.pc-min-width-375 {
  min-width: 375px;
}

.pc-min-width-550 {
  min-width: 550px;
}

.h-180 {
  height: 180px !important;
}

.pc-flex-end {
  align-items: flex-end;
}

//inline edit
.inline-text_copy--active,
.inline-text_input--active {
  font: inherit;
  color: inherit;
  text-align: inherit;
  padding: 0;
  background: none;
  border: none;
}

.inline-text .fa-edit {
  cursor: pointer;
  font-size: initial;
}

.inline-text_input--active {
  border-bottom: 1px solid #666666;
  text-align: left;
  width: 300px;
}

.pc-overflow-x-auto {
  overflow: auto !important;
}

.padding-horizontal-8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.height-810 {
  height: 810px !important;
}

.pc-task-description {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  max-height: 4.5em;
  overflow-y: hidden;
  position: relative;
  font-size: 13px;

  &::before {
    position: absolute;
    content: "\02026";
    bottom: 0.7px;
    right: 0;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    width: 1rem;
    height: 1rem;
    background: white;
  }
}

.pc-task-description-show-more {
  text-decoration: underline;
}

.pc-account-details-input {
  flex-grow: 1;

  @media only screen and (min-width: 1600px) {
    flex-basis: calc(16% - 1rem);
  }
}

.pc-service-address-input-label {

  //if media query is met, it will strip whitespace from label
  @media only screen and (min-width: 1448px) {
    white-space: nowrap;
  }

  //if media query met it, it will set all the columns' labels min-height to a max of 3 lines.
  //using the following so all the column labels in the row have the same height for the moment when bootstrap's grid starts breaking the labels content
  //into multiple lines

  @media only screen and (min-width: 768px) and (max-width: 1447px) {
    min-height: 4.2em;
  }
}

.pc-no-resize {
  resize: none;
}

.margin-top-29 {
  margin-top: 29px;
}

.margin-top-0 {
  margin-top: 0px !important;
  margin-bottom: 0.5rem !important;
}

.comment-size {
  font-size: 30px;
}

.ml-text {
  margin-left: 1.5rem !important;
  margin-top: -35px;
}

.pc-required-label:after {
  content: " *";
  color: #fb6340;
}

.pc-flex-button-container {
  display: flex;
  align-items: center;

  :not(.align-left):first-child:before {
    content: "";
  }

  :not(.align-left):first-child {
    margin-left: auto;
  }

  .align-left {
    margin-right: auto;
  }
}

.table-header {
  font-size: 16px !important;
  font-weight: 600;
  color: #525F7F;
  margin-top: -25px;
  margin-left: 15px;

}

.table-subHeader {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  margin-left: 15px;
  margin-top: 2px;
}

.text-black {
  color: #000;
}

.nav-link-table {
  font-size: 14px;
  font-weight: 400;
  margin-left: 3px;
  color: #2E5599;
  margin-top: 10px;
}

.nav-subLink {
  margin-top: 3px;
  margin-left: -10px;
  color: #000000;
}

.margin-left-10 {
  margin-left: -10px;
}

.color-blue {
  color: #4196D2 !important;
}

.activities-card {
  margin-left: 12px;
  margin-right: 12px;
  background-color: #F6F9FC;
}

.sub-card-header {
  margin-left: -28px;
  margin-right: -26px;
  margin-top: -23px;
  background-color: #6B7692;
  border-radius: 4px 4px 0px 0px
}

.sub-card-body-header {
  margin-bottom: -50px;
  margin-top: -17px;
  margin-left: -33px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 600;
}

.sub-card-header-2 {
  margin-left: -29px;
  margin-top: -30px;
  margin-right: -27px;
  background-color: #F6F9FC
}

.sub-card-body-header-2 {
  margin-bottom: -40px;
  margin-top: -12px;
  margin-left: -33px;
  font-size: 14px;
  color: #525F7F;
  font-weight: 600;
}

.sub-card-message {
  font-size: 12px;
  font-weight: 400;
}

.sub-card-form {
  margin-left: -38px;
  margin-top: -7px;
  font-size: 14px;
  font-weight: 600
}

.pl-half {
  padding-left: 0.5rem !important;
}

.font-size-14 {
  font-size: 14px;
}

.background-color-ul {
  background-color: #F6F9FC;
}

.border-radius {
  border-radius: 0.375rem !important;
}

.border-settings {
  background: #6B7692;
  border-radius: 4px 4px 0px 0px;
}

.checkbox-settings {
  padding-top: 5px;
  color: #FFFFFF;
  font-size: 1;
  font-size: 14px;
  font-weight: 600;
}

.checkbox-settings-sub {
  color: #525F7F;
  font-size: 14px;
  font-weight: 600;
}

.divider {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.modal-body .row {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.row-negative {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px !important;
  margin-right: -15px !important;
}

// clear default tabs/nav-pills bg-color and font-color style
#account-tabs.nav-pills .nav-link {
  background-color: unset;
  color: unset;
}

// efx for a selected tab
.account-tab-active-nav-item {
  background-color: white;
  border-bottom: 2px solid $primary;
}

.account-tab-active-nav-link {
  font-weight: bold !important;
  color: $primary !important;
}

//efx for an 'un-selected tab'
#account-tabs.nav-pills {
  background-color: #EFEFF2;
  color: rgba($primary, .5) !important;
}

//efx when focusing/hovering over tab
#account-tabs.nav-pills>li:hover {
  background-color: #E3E3E3;
  color: rgba($primary, 1) !important;
}

.pc-order-status-warning {
  color: #fbc940;
}

.pc-npac-maintenance-warning {
  margin-top: 0px;
  margin-left: 0px;
}

.pc-npac-maintenance-modal-warning {
  margin-top: 10px;
  margin-left: 15px;
}

.pc-npac-maintenance-warning>a,
.pc-npac-maintenance-modal-warning>a {
  text-decoration: underline;
  color: white !important;
}

.pc-react-table-custom-view-settings {
  max-height: 300px !important;
}

.pc-react-table-custom-view-settings-tab-content {
  height: 316px;
  width: 364px;
  padding-left: 12px;
}

.list-items-into-columns {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 4;
  column-gap: 20px;
}

.shortcut-item small {
  font-size: 0.7rem !important;
}

.info-tooltip-arrow::before {
  border-right-color: $info !important;
}

.Toastify__toast-container--top-center {
  width: unset !important;
  margin-left: -18vw !important;
}

.pc-manage-organization-account-type-identifier {
  font-family: "Open Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.pc-manage-organization-account-type-identifier-parent {
  color: #527f5c;
}

.pc-manage-organization-account-type-identifier-child {
  color: #fb6340;
}

.pc-manage-organization-account-type-identifier-stand-alone {
  color: #2e5599;
}

.text-primary {
  color: #2e5599 !important;
}

.border-1 {
  border: 1px solid #e9ecef;
}

.border-top-radius {
  border-top-right-radius: 0.375rem;
  border-top-left-radius: 0.375rem;
}

.row {
  margin-right: 0px !important;
  margin-left: -0px !important;
}

.pc-manage-organization-associated-spids {
  white-space: break-spaces;
}

.pc-manage-organization-row-expander-inactive {
  color: #6c7793;
}

.pc-manage-organization-row-expander-active {
  color: #6c7793;
  transform: rotate(90deg);
}

.print-only {
  display: none;

  @media print {
    display: block;
  }
}

.min-height-120 {
  min-height: 140px;
}

.text-break {
  white-space: normal !important;
}

.pointer-events-none {
  pointer-events: none;
}

.select2.select2-container {
  width: 100% !important;
  min-width: 220px;
}

.overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
}

.overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}


@media only screen and (max-width: 2990px) {
  .pc-count-card-container {
    flex: 0 1 calc(20% - 8px);
  }

  .pc-count-card {
    min-height: 116px;
  }
}

@media only screen and (max-width: 1368px) {
  .pc-count-card-container {
    flex: 0 1 calc(33% - 8px);
  }
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container--top-center {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 1088px) {
  .pc-count-card-container {
    flex: 0 1 calc(50% - 8px);
  }
}

@media only screen and (max-width: 991px) {
  .pc-hidden-lg {
    display: block;
  }

  .pc-hidden-sm {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .pc-portin-back-button {
    white-space: normal;
  }
}

@media only screen and (max-width: 501px) {
  .pc-count-card-container {
    flex: 0 1 calc(100% - 8px);
  }
}

@media only screen and (max-width: 460px) {
  .pc-navbar-img {
    height: 40px !important;
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
  }
}

@media (min-width: 1500px) {
  .table-responsive {
    overflow-x: visible;
  }
}

@media (min-width: 1400px) {
  .modal-xl {
    max-width: 1200px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1000px;
  }
}

@media (min-width: 992px) {
  .pc-modal-xlg {
    max-width: 1200px;
  }

  .modal-xl {
    max-width: 800px;
  }
}

.real-brand-text {
  color: #2E5599;
}

.font-weight-bolder {
  font-weight: bolder;
}

.font-size-base {
  font-size: 15px;
}